import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import {StyledBgImgSection} from "../components/Header/styles";
import OurServices from "../components/Sections/OurServices";
import PageHero from "../components/PageHero/PageHero";
function ServicesPage() {
	return (
		<Layout>
			<SEO
				title="Services :: Fast Optimized Static Sites Development"
				description="We create fast, optimized, responsive, mobile first, static websites using Gatsby / React. Web Development Agency Tampa Florida"
				keywords={['static websites', 'gatsby website', 'react site', 'quick site', 'fast site', 'optimized webpage', 'responsive website', 'mobile firts sites', 'create site in Tampa', 'order website']}
			/>

			<PageHero title="Our Services" />
			<OurServices/>
		</Layout>
	);
}

export default ServicesPage;
